/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BiMenu } from 'react-icons/bi';
import {
  Box,
  chakra,
  Flex,
  forwardRef,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { Avatar, Button, Text, toast } from '@scope';
import cookies from 'js-cookie';
import { useSWRConfig } from 'swr';

import userApi from 'api/user';
import useCurrentUser from 'libs/useCurrentUser';
import useMobile from 'libs/useMobile';
// import { BsBellFill } from 'react-icons/bs';
import { FEATURE } from 'res/betaFeature';
import { checkBetaFeature } from 'utils/betaFeature';
import { isAgency, isVendor } from 'utils/roles';
import {
  getBusinessProfileLink,
  getUserAvatar,
  getUserGroup,
} from 'utils/user';
import HeaderLink from './Link';

// const Bell = chakra(BsBellFill);
const MobileMenu = chakra(BiMenu);

function Header() {
  const { user } = useCurrentUser();
  const { cache } = useSWRConfig();
  const { isMobile } = useMobile();
  const router = useRouter();

  // Beta feature flag
  const betaFeatureId = FEATURE.Referrals;

  const [isEnabled, setIsEnabled] = useState(false);
  const [id, setRole] = useState(0);

  useEffect(() => {
    const userId = user?.id;
    if (!userId) return;
    setRole(user.id);
    const checkFunction = async () => {
      const check = await checkBetaFeature(user, betaFeatureId);
      setIsEnabled(check);
    };
    checkFunction();
  }, [id, betaFeatureId, user]);

  // Conditional variables
  const showExperts = user && isVendor(user.role);
  const showReferrals =
    user && isEnabled && (isVendor(user.role) || isAgency(user.role));
  const showVendors = user && isAgency(user.role);
  const showAnalytics = user && isVendor(user.role);

  const renderMenu = () => {
    if (!user) {
      return null;
    }

    const userName = `${user.firstName} ${user.lastName[0]}.`;
    const userGroup = getUserGroup(user);
    const businessProfileLink = getBusinessProfileLink(user);

    const handleLogout = async () => {
      try {
        await userApi.logout();

        cookies.remove('at', {
          path: '/',
        });
        cookies.remove('rt', {
          path: '/',
        });

        cache.clear();

        router.push('/login');
      } catch (err) {
        toast(err.message, 'error', { id: 'user-logout' });
      }
    };

    const ExpertSubmenu = forwardRef((props, ref) => (
      <Menu>
        <MenuButton ref={ref} {...props}>
          Experts
        </MenuButton>
        <Portal>
          <MenuList>
            <Link href="/experts">
              <a>
                <MenuItem>My Experts</MenuItem>
              </a>
            </Link>
            {showReferrals && (
              <Link href="/experts/referrals">
                <a>
                  <MenuItem>My Referrers</MenuItem>
                </a>
              </Link>
            )}
            <Link href="/experts/directory">
              <a>
                <MenuItem>Marketplace</MenuItem>
              </a>
            </Link>
          </MenuList>
        </Portal>
      </Menu>
    ));

    return (
      <>
        {isMobile ? (
          <Menu placement="top">
            <MenuButton>
              <MobileMenu color="ui.white" size="24px" cursor="pointer" />
            </MenuButton>

            <MenuList>
              <Link href="/projects">
                <a>
                  <MenuItem>Projects</MenuItem>
                </a>
              </Link>
              {isVendor(user.role) && <MenuItem as={ExpertSubmenu} />}
              {isAgency(user.role) && (
                <Link href={`/profile/${user.id}`}>
                  <a>
                    <MenuItem>Profile</MenuItem>
                  </a>
                </Link>
              )}
              {showVendors && (
                <Link href="/vendor">
                  <a>
                    <MenuItem>Vendors</MenuItem>
                  </a>
                </Link>
              )}
              {isVendor(user.role) && (
                <Link href="/analytics">
                  <a>
                    <MenuItem>Analytics</MenuItem>
                  </a>
                </Link>
              )}
              <Link href={businessProfileLink}>
                <a>
                  <MenuItem>Business Profile</MenuItem>
                </a>
              </Link>
              <Link href="/settings">
                <a>
                  <MenuItem>Account Settings</MenuItem>
                </a>
              </Link>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Flex align="center" experimental_spaceX={{ md: '7', lg: '14' }}>
            {/* <Bell color="ui.gray.light" size="20px" cursor="pointer" /> */}

            <Menu placement="top">
              <MenuButton textAlign="left">
                <Flex cursor="pointer">
                  <Avatar
                    name={userName}
                    role={user.role}
                    src={getUserAvatar(user, 40)}
                    size="10"
                  />

                  <Flex direction="column" ml="2">
                    <Text ms={0} bold color="ui.white">
                      {userName}
                    </Text>

                    <Text
                      ms={-1}
                      color="ui.white"
                      maxW="100px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {userGroup}
                    </Text>
                  </Flex>
                </Flex>
              </MenuButton>

              <MenuList>
                {isAgency(user.role) && (
                  <Link href={`/profile/${user.id}`}>
                    <a>
                      <MenuItem>Profile</MenuItem>
                    </a>
                  </Link>
                )}

                <Link href={businessProfileLink}>
                  <a>
                    <MenuItem>Business Profile</MenuItem>
                  </a>
                </Link>

                <Link href="/settings">
                  <a>
                    <MenuItem>Account Settings</MenuItem>
                  </a>
                </Link>

                <a
                  href="https://www.scopeinc.com/faq"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <MenuItem>FAQs</MenuItem>
                </a>

                <MenuDivider />

                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
      </>
    );
  };

  return (
    <Flex mb={{ base: '4', sm: '16' }} direction="column">
      <Flex
        align="center"
        justify="space-between"
        bgColor="ui.black.bg"
        w="100%"
        h="14"
        mx="auto"
        px={{ base: '4', sm: '60px' }}
      >
        <Flex
          align="center"
          justify="space-between"
          experimental_spaceX={{ base: 4, md: '8' }}
          h="100%"
        >
          <Box pt="2">
            <Link href="/" passHref>
              <chakra.a
                display="flex"
                position="relative"
                w={{ base: '78px', sm: '97.5px' }}
                h={{ base: '24px', sm: '30px' }}
              >
                <Image src="/logo.svg" alt="Scope Logo" layout="fill" />
              </chakra.a>
            </Link>
          </Box>

          {/* Hide main nav links on mobile */}
          {!isMobile && (
            <>
              <HeaderLink href="/projects">Projects</HeaderLink>

              {showExperts && (
                <Menu gutter={0}>
                  <MenuButton
                    as={Button}
                    variant="link"
                    color="white"
                    px="16px!important"
                    height="100%"
                  >
                    Experts
                  </MenuButton>
                  <MenuList
                    p="2"
                    borderRadius="0px"
                    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.08)"
                    color="ui.black.bg"
                  >
                    <Link href="/experts">
                      <a>
                        <MenuItem>My Experts</MenuItem>
                      </a>
                    </Link>
                    {showReferrals && (
                      <Link href="/experts/referrals">
                        <a>
                          <MenuItem>My Referrers</MenuItem>
                        </a>
                      </Link>
                    )}
                    <Link href="/experts/directory">
                      <a>
                        <MenuItem>Marketplace</MenuItem>
                      </a>
                    </Link>
                  </MenuList>
                </Menu>
              )}
              {showVendors && <HeaderLink href="/vendor">Vendors</HeaderLink>}
              {showAnalytics && (
                <HeaderLink href="/analytics">Analytics</HeaderLink>
              )}

              {showReferrals && (
                <HeaderLink href="/referrals">Referrals</HeaderLink>
              )}
            </>
          )}
        </Flex>

        {renderMenu()}
      </Flex>
    </Flex>
  );
}

export default Header;
