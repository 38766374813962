import API from 'config/axios';

const login = async (userData: { email: string; password: string }) => {
  try {
    const { data } = await API.post(`/auth/login`, userData);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const logout = async () => {
  try {
    const { data } = await API.post(`/auth/logout`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const registerCustomerUser = async (userData) => {
  try {
    const { data } = await API.post(`/user/customer`, {
      ...userData,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const registerCustomerUserNoInvitation = async (userData) => {
  try {
    const { data } = await API.post(`/user/customer/signup`, {
      ...userData,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const registerAgencyUser = async (userData) => {
  try {
    const { data } = await API.post(`/user/agency`, {
      ...userData,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const registerVendorUser = async (userData) => {
  try {
    const { data } = await API.post(`/user/vendor`, {
      ...userData,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const getCurrentUser = async () => {
  try {
    const { data } = await API.get('/user/me');

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// NOTE: no need to async/await since we want to just fire
// and forget from this call since we don't need data back
const identifyUser = async () => {
  try {
    await API.post('/user/identify');
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const getApiKeys = async () => {
  try {
    const { data } = await API.get('/user/api-keys/');

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const generateApiKey = async (name) => {
  try {
    const { data } = await API.post('/user/api-keys/generate', {
      name,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const deleteApiKey = async (keyId) => {
  try {
    const { data } = await API.delete(`/user/api-keys/delete/${keyId}`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const forgotPassword = async (email) => {
  try {
    const { data } = await API.post(`/auth/forgot`, {
      email,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const resetPassword = async (_data) => {
  try {
    const { data } = await API.post(`/auth/reset`, _data);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const changePassword = async (_data) => {
  try {
    const { data } = await API.post(`/user/profile/change-password`, _data);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const updateEmailPreferences = async (emailPreferencesIds: number[]) => {
  try {
    const { data } = await API.post(`/user/email-preferences`, {
      emailPreferencesIds,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const userApi = {
  login,
  logout,
  registerCustomerUser,
  registerCustomerUserNoInvitation,
  registerAgencyUser,
  registerVendorUser,
  getCurrentUser,
  identifyUser,
  getApiKeys,
  generateApiKey,
  deleteApiKey,
  forgotPassword,
  resetPassword,
  changePassword,
  updateEmailPreferences,
};

export default userApi;
