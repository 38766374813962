import { ReactNode } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Header from 'components/Header';
import useCurrentUser, {
  checkRoutePermissions,
  UseUserProps,
} from 'libs/useCurrentUser';

type LayoutOptions = {
  hideHeader?: boolean;
  redirectWithCookie?: boolean;
  isPublic?: boolean;
  useUserProps?: UseUserProps;
};

type LayoutProps = {
  title?: string;
  children: ReactNode;
  options?: LayoutOptions;
};

function Layout({ title, children, options = {} }: LayoutProps) {
  const {
    hideHeader,
    redirectWithCookie = true,
    isPublic = false,
    useUserProps = {},
  } = options;

  const { user } = useCurrentUser(useUserProps);

  const router = useRouter();

  const renderCookieScript = () => {
    if (isPublic) {
      return (
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              document.documentElement.classList.add('render');
            `,
          }}
        />
      );
    }

    // If redirectWithCookie isn't set to false we check for cookie named `token` and if it doesn't exist => redirect to login page.
    // .render is added to remove white overflow from body::before, when the page is rendered (after auth is checked and we decided if we should redirect the user with useCurrentUser hook).
    if (redirectWithCookie) {
      return (
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              if (!document.cookie || (document.cookie.indexOf('rt=') === -1 || document.cookie.indexOf('at=') === -1)) {
                document.cookie = "rt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "at=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                location.replace('/login?next=' + encodeURIComponent(location.pathname));
              } else {
                document.documentElement.classList.add('render');
              }
            `,
          }}
        />
      );
    }

    return (
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            if (!document.cookie || (document.cookie.indexOf('rt=') === -1 || document.cookie.indexOf('at=') === -1)) {
                document.cookie = "rt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "at=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

              document.documentElement.classList.add('render');
            }
          `,
        }}
      />
    );
  };

  const renderContent = () => {
    const isAuthorized =
      isPublic ||
      useUserProps.redirectIfFound ||
      (user &&
        !checkRoutePermissions(
          router,
          user,
          useUserProps.allowedRoles,
          useUserProps.betaFeatureId,
        ));

    if (isAuthorized) {
      return children;
    }

    return null;
  };

  return (
    <>
      <Head>
        <title>{title ? `${title} – Scope` : `Scope`}</title>

        {renderCookieScript()}
      </Head>

      {!hideHeader && <Header />}

      {renderContent()}
    </>
  );
}

export const getLayout = (
  page: ReactNode,
  title?: string,
  options?: LayoutOptions,
) => (
  <Layout title={title} options={options}>
    {page}
  </Layout>
);

export default Layout;
