import Link from 'next/link';
import { chakra } from '@chakra-ui/react';
import { Text } from '@scope';

type HeaderLinkProps = {
  href: string;
  children: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

function HeaderLink({ href, children, ...props }: HeaderLinkProps) {
  return (
    <Text
      ms={0}
      bold
      px="4"
      color="ui.white"
      h="100%"
      display="flex"
      {...props}
    >
      <Link href={href} passHref>
        <chakra.a
          _hover={{ textDecoration: 'underline' }}
          display="flex"
          alignItems="center"
        >
          {children}
        </chakra.a>
      </Link>
    </Text>
  );
}

export default HeaderLink;
